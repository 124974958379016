<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  listPayMoneyAPI,
  listPayMoneySummaryAPI,
  listPayQtyAPI,
  listPayQtySummaryAPI,
  listScoreQty,
  listScoreQtySummary
} from '@/api/vip/report/anomalyQuery'
export default {
  name: 'anomalyQuery',
  components: { TablePage },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员异常查询',
        rowKey: '',
        search: [
          {
            type: 'filters',
            tip: '会员卡号/会员名称/手机号',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'tels', label: '手机号' }
            ]
          },
          {
            type: 'input',
            label: '付款次数',
            model: '',
            filter: 'payQty',
            seniorSearch: true
          },
          {
            type: 'input',
            label: '付款金额',
            model: '',
            filter: 'payMoney',
            seniorSearch: true
          },
          {
            type: 'input',
            label: '积分次数',
            model: '',
            filter: 'scoreQty',
            seniorSearch: true
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabsColumns: [
          {
            title: '会员付款次数异常',
            getListApi: listPayQtyAPI,
            getSummaryApi: listPayQtySummaryAPI,
            type: 'vipPayQtyList',
            exportOption: {
              exportApi: 'vipAnomalyQtyQuery',
              exportName: '会员付款次数异常'
            },
            columns: [
              {
                prop: 'billDate',
                label: '日期',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'payQty',
                label: '付款次数',
                minWidth: 150
              }
            ],
            summary: ['payQty']
          },
          {
            title: '会员付款金额异常',
            getListApi: listPayMoneyAPI,
            getSummaryApi: listPayMoneySummaryAPI,
            type: 'vipPayMoneyList',
            exportOption: {
              exportApi: 'vipAnomalyMoneyQuery',
              exportName: '会员付款金额异常'
            },
            columns: [
              {
                prop: 'billDate',
                label: '日期',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'payMoney',
                label: '付款金额',
                minWidth: 150
              }
            ],
            summary: ['payMoney']
          },
          {
            title: '会员积分次数异常',
            getListApi: listScoreQty,
            getSummaryApi: listScoreQtySummary,
            type: 'vipScoreList',
            exportOption: {
              exportApi: 'vipAnomalyScoreQuery',
              exportName: '会员积分次数异常'
            },
            columns: [
              {
                prop: 'billDate',
                label: '日期',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'scoreQty',
                label: '积分次数',
                minWidth: 150
              }
            ],
            summary: ['scoreQty']
          }
        ]
      }
    }
  }
}
</script>
