<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { listBecomeDueAPI,listBecomeDueSummaryAPI } from '@/api/vip/report/endValidQuery' //会员到期明细查询
import { getVipReportDefaultCondition } from '@/api/vip/report/lossQuery.js' 


export default {
  name: 'endValidQuery',
  components: { TablePage },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        defaultBody: async () => {
          try{
            const { data }  = await getVipReportDefaultCondition(2)
            if(data){
              this.options.search[2].model = [Number(data.beg),Number(data.end)]
              return {
                beginExpirationDays:Number(data.beg),
                endExpirationDays:Number(data.end)
              }
            }
          }catch(err){}
        },
        getListApi: listBecomeDueAPI,
        getSummaryApi: listBecomeDueSummaryAPI,
        title: '会员到期查询',
        rowKey: 'vipId',
        exportOption: {
          exportApi: 'vipEndValidQuery',
          exportName: '会员到期'
        },
        search: [
          {
            type: 'filters',
            tip: '级别编码/级别名称/会员卡号/会员名称/手机号',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipLevelNos', label: '级别编码' },
              { filter: 'vipLevelNames', label: '级别名称' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'tels', label: '手机号' }
            ]
          },
          this.$select({
            key: 'viplevel',
            option: {
              filter: 'vipLevelIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          {
            type: 'numberRange',
            label: '到期天数',
            filter: 'beginExpirationDays',
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              beginExpirationDays: one,
              endExpirationDays: two
            })
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 150
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 150
          },
          {
            prop: 'tel',
            label: '手机号',
            minWidth: 150
          },
          {
            prop: 'startUseTime',
            label: '开始消费日期',
            minWidth: 150
          },
          {
            prop: 'lastUseTime',
            label: '最近消费日期',
            minWidth: 150
          },
          {
            prop: 'consumeCount',
            label: '累计消费次数',
            minWidth: 150
          },
          {
            prop: 'allConsumeMoney',
            label: '累计消费金额',
            minWidth: 150
          },
          {
            prop: 'payCount',
            label: '支付次数',
            minWidth: 150
          },
          {
            prop: 'allFillMoney',
            label: '累计充值金额',
            minWidth: 150
          },
          {
            prop: 'usableFillMoney',
            label: '可用充值金额',
            minWidth: 150
          },
          {
            prop: 'allScore',
            label: '累计积分',
            minWidth: 150
          },
          {
            prop: 'usableScore',
            label: '可用积分',
            minWidth: 150
          },
          {
            prop: 'vipLevelNo',
            label: '级别编号',
            minWidth: 150
          },
          {
            prop: 'vipLevelName',
            label: '级别名称',
            minWidth: 150
          },
          {
            prop: 'begValidDate',
            label: '生效日期',
            minWidth: 150
          },
          {
            prop: 'endValidDate',
            label: '结束日期',
            minWidth: 150
          },
          {
            prop: 'shopName',
            label: '发卡门店',
            minWidth: 150
          }
        ],
        summary: [
          'consumeCount',
          'allConsumeMoney',
          'payCount',
          'allFillMoney',
          'usableFillMoney',
          'allScore',
          'usableScore'
        ]
      }
    }
  }
}
</script>
