vue
<template>
  <div class="date-picker-plus">
    <el-date-picker
      v-model="selectedDates"
      type="dates"
      format="dd"
      :picker-options="pickerOptions"
      placeholder="请选择日期"
      :disabled="disabled"
      :clearable="clearable"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'DatePickerPlus',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    initialSelectedDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDates: [],
      pickerOptions: {
        disabledDate: time => {
          const day = time.getDate()
          return day < 1 || day > 31
        }
      }
    }
  },
  mounted() {
    this.selectedDates = this.initialSelectedDates.map(
      day => new Date(2022, 0, day)
    ) // Assuming year 2022 for example
  },
  watch: {
    initialSelectedDates: {
      handler(newVal) {
        this.selectedDates = newVal.map(day => new Date(2022, 0, day)) // Assuming year 2022 for example
      },
      immediate: true
    },
    selectedDates() {
      if (this.selectedDates.length > 0) {
        let dateArr = this.selectedDates.map(date => date.getDate())
        this.$emit('datePickerValue', dateArr)
      }
    }
  }
}
</script>

<style scoped>
.date-picker-plus {
  display: inline-block;
  width: 100%;
}
.el-date-picker__header {
  display: none;
}
</style>
