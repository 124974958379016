var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("el-card", [
        _c("div", [
          _c("div", { staticClass: "distributionTitleText" }, [
            _c("span", [_vm._v("会员概览")]),
          ]),
          _c("div", { staticClass: "x-bc marT30 marB30" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData ? _vm.overviewData.totalQty : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("会员总数")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData ? _vm.overviewData.totalMoney : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("会员总余额")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData ? _vm.overviewData.totalScore : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("会员总积分")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData
                        ? _vm.overviewData.customerUnitPrice
                        : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("会员客单价")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData
                        ? _vm.overviewData.customerOrdersQty
                        : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("会员客单数")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData
                        ? _vm.overviewData.allFillMoney
                        : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("累计充值金额")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData
                        ? _vm.overviewData.allLargessMoney
                        : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("累计赠送金额")]),
            ]),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.overviewData
                        ? _vm.overviewData.allConsumeMoney
                        : "Loading"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "label" }, [_vm._v("累计消费金额")]),
            ]),
          ]),
        ]),
      ]),
      _c("el-card", { staticClass: "marT10" }, [
        _c("div", [
          _c("div", { staticClass: "x-bc" }, [
            _c("div", { staticClass: "distributionTitleText" }, [
              _c("span", [_vm._v("会员同比分析")]),
            ]),
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      on: {
                        change: function ($event) {
                          return _vm.fetchOverviewData("vipYOY")
                        },
                      },
                      model: {
                        value: _vm.selectedTimeFilter,
                        callback: function ($$v) {
                          _vm.selectedTimeFilter = $$v
                        },
                        expression: "selectedTimeFilter",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "thisWeek" } }, [
                        _vm._v("本周"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "thisMonth" } }, [
                        _vm._v("本月"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "thisYear" } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "x-bc marT30 marB30" },
            _vm._l(_vm.statisticsData, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "top marL10 padd10 y-f-j" },
                [
                  _c("div", { staticClass: "y-start marL15" }, [
                    _c("div", [
                      _vm._v(_vm._s(item.label) + ": " + _vm._s(item.value)),
                    ]),
                  ]),
                  _c("div", { staticClass: "y-c" }, [
                    _c("div", { staticClass: "info2 x-bc" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "x-f",
                            style:
                              "color:" +
                              (item.yoyGrowth >= 0 ? "#ff2d46" : "#56cc56"),
                          },
                          [
                            _vm._v(" " + _vm._s(item.yoyGrowth) + "% "),
                            _c("iconpark-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.yoyGrowth != 0,
                                  expression: "item.yoyGrowth != 0",
                                },
                              ],
                              attrs: { name: _vm.getArrowIcon(item.yoyGrowth) },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "rechargeRace" }, [
                          _vm._v("同比增长"),
                        ]),
                      ]),
                      _c("div", { staticClass: "dashed-line" }),
                      _c("div", { staticClass: "infoRight" }, [
                        _c(
                          "div",
                          {
                            staticClass: "x-f",
                            style:
                              "color:" +
                              (item.momGrowth >= 0 ? "#ff2d46" : "#56cc56"),
                          },
                          [
                            _vm._v(" " + _vm._s(item.momGrowth) + "% "),
                            _c("iconpark-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.momGrowth != 0,
                                  expression: "item.momGrowth != 0",
                                },
                              ],
                              attrs: { name: _vm.getArrowIcon(item.momGrowth) },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "rechargeRace" }, [
                          _vm._v("环比增长"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "styleBox x-bc" },
        [
          _c("el-card", { staticClass: "marT10 styleLeft" }, [
            _c("div", [
              _c("div", { staticClass: "distributionTitleText" }, [
                _c("span", [_vm._v("会员类型分布")]),
              ]),
              _c("div", { staticClass: "x-f" }, [
                _c("div", { staticClass: "y-start marT15" }, [
                  _c("div", { staticClass: "marT10" }, [
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.overviewData.totalQty)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v("会员总数(人)"),
                      ]),
                    ]),
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.overviewData.onlineQty)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v("线上会员总数(人)"),
                      ]),
                    ]),
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.overviewData.offlineQty)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v("线下会员总数(人)"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", {
                  staticStyle: { width: "100%", height: "300px" },
                  attrs: { id: "chart-style" },
                }),
              ]),
            ]),
          ]),
          _c("el-card", { staticClass: "marT10 styleRight" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "x-bc" },
                [
                  _c("div", { staticClass: "distributionTitleText" }, [
                    _c("span", [_vm._v("会员性别分析")]),
                  ]),
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo marL30",
                      attrs: {
                        "default-active": _vm.activeIndex,
                        mode: "horizontal",
                      },
                      on: { select: _vm.handleSelect },
                    },
                    [
                      _c("el-menu-item", { attrs: { index: "1" } }, [
                        _vm._v("人数"),
                      ]),
                      _c("el-menu-item", { attrs: { index: "2" } }, [
                        _vm._v("金额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "x-f" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "300px" },
                  attrs: { id: "chart-sex-right" },
                }),
                _c("div", { staticClass: "y-end marT5" }, [
                  _c("div", {}, [
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.manData)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              "男性" + (_vm.activeIndex == "1" ? "(人)" : "元")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.maleData)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              "女性" + (_vm.activeIndex == "1" ? "(人)" : "元")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bgc" }, [
                      _c("div", { staticClass: "data" }, [
                        _vm._v(_vm._s(_vm.unknowData)),
                      ]),
                      _c("div", { staticClass: "recharge" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              "未知占比" +
                                (_vm.activeIndex == "1" ? "(人)" : "元")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("el-card", { staticClass: "marT10" }, [
        _c("div", [
          _c("div", { staticClass: "x-bc" }, [
            _c("div", { staticClass: "distributionTitleText" }, [
              _c("span", [_vm._v("消费趋势分析")]),
            ]),
            _c("div", [
              _c(
                "div",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      size: "mini",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.fetchOverviewData("vipConsumer")
                      },
                    },
                    model: {
                      value: _vm.pickerTime,
                      callback: function ($$v) {
                        _vm.pickerTime = $$v
                      },
                      expression: "pickerTime",
                    },
                  }),
                  _c("timeRange", {
                    attrs: {
                      format: "daterange",
                      "default-value": "week",
                      size: "mini",
                      data: ["week", "month", "year"],
                    },
                    on: { change: _vm.handleTimeRangeChange },
                    model: {
                      value: _vm.pickerTime,
                      callback: function ($$v) {
                        _vm.pickerTime = $$v
                      },
                      expression: "pickerTime",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "420px" },
            attrs: { id: "chart-container" },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "styleBox x-bc" },
        [
          _c("el-card", { staticClass: "marT10 styleLeftAge" }, [
            _c("div", [
              _c("div", { staticClass: "x-bc" }, [
                _c("div", { staticClass: "distributionTitleText" }, [
                  _c("span", [_vm._v("年龄分布与消费金额")]),
                ]),
                _c("div", { staticClass: "x-bc" }, [
                  _c(
                    "div",
                    { staticClass: "marL30" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndexAge,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelectAge },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("人数"),
                          ]),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("金额"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#409eff",
                        cursor: "pointer",
                        "margin-left": "30px",
                        "margin-bottom": "15px",
                        "font-size": "15px",
                      },
                      attrs: { id: "myDiv2" },
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisible = true
                        },
                      },
                    },
                    [_vm._v(" 设置 ")]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "x-b" },
                [
                  _c("div", {
                    staticStyle: { width: "500px", height: "400px" },
                    attrs: { id: "chart-age-right" },
                  }),
                  _c(
                    "div",
                    { staticClass: "y-f rightBox" },
                    _vm._l(_vm.overviewData.ageQtyList, function (item, i) {
                      return _c("div", { key: i, staticClass: "ageBgc x-f" }, [
                        _c("div", { staticClass: "ageData" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getQtyRangeTextAge(
                                  item.beginAge,
                                  item.endAge
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "ageRecharge" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedKeyType === "1"
                                  ? item.ageQty + "人"
                                  : item.consumeMoney + "元"
                              ) +
                              " "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "el-dialog",
                    {
                      directives: [
                        { name: "dialogDrag", rawName: "v-dialogDrag" },
                      ],
                      attrs: {
                        title: "会员年龄段划分 (注:最多分10段)",
                        visible: _vm.dialogTableVisible,
                        width: "820px",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogTableVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.gridData,
                            border: "",
                            "max-height": "400px",
                            height: "400px",
                            width: "auto",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "date",
                              label: "操作",
                              width: "120px",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-plus operatePush",
                                      class: {
                                        disabled: _vm.gridData.length >= 9,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowAvailable("push")
                                        },
                                      },
                                    }),
                                    _c("i", {
                                      staticClass: "el-icon-remove operateDel",
                                      class: {
                                        disabled: _vm.gridData.length === 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearLastRowDataAge(
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "name",
                              label: "起始年龄",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "inputWidth",
                                      attrs: { type: "number", width: "80px" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.validateAgeInput(
                                            scope.row,
                                            "beginAge"
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.beginAge,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "beginAge", $$v)
                                        },
                                        expression: "scope.row.beginAge",
                                      },
                                    }),
                                    _c("span", { staticClass: "marL10" }, [
                                      _vm._v("岁"),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "至",
                              width: "60px",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "address",
                              label: "结束年龄",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "inputWidth",
                                      attrs: { type: "number", width: "80px" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.validateAgeInput(
                                            scope.row,
                                            "endAge"
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.endAge,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "endAge", $$v)
                                        },
                                        expression: "scope.row.endAge",
                                      },
                                    }),
                                    _c("span", { staticClass: "marL10" }, [
                                      _vm._v("岁"),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialogTableVisible = false
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.updateQtyRangeAndFetchDataAge },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-card", { staticClass: "marT10 styleRightQty" }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "x-bc" }, [
                  _c("div", { staticClass: "distributionTitleText" }, [
                    _c("span", [_vm._v("会员消费次数")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#409eff",
                        cursor: "pointer",
                        "margin-bottom": "15px",
                        "font-size": "15px",
                      },
                      attrs: { id: "myDiv" },
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisibleQty = true
                        },
                      },
                    },
                    [_vm._v(" 设置 ")]
                  ),
                ]),
                _c("div", { staticClass: "x-b" }, [
                  _c("div", {
                    staticStyle: { width: "500px", height: "400px" },
                    attrs: { id: "chart-style-right" },
                  }),
                  _c(
                    "div",
                    { staticClass: "y-f rightBox" },
                    _vm._l(
                      _vm.overviewData.consumptionRangeResps,
                      function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "ageBgc x-f" },
                          [
                            _c("div", { staticClass: "ageData" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getQtyRangeText(
                                      item.beginQty,
                                      item.endQty
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "ageRecharge" }, [
                              _vm._v(_vm._s(item.peopleQty) + "人"),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "会员消费次数划分 (注:最多分10段)",
                      visible: _vm.dialogTableVisibleQty,
                      width: "820px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogTableVisibleQty = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.gridDataQty,
                          border: "",
                          "max-height": "400px",
                          height: "400px",
                          width: "auto",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            property: "date",
                            label: "操作",
                            width: "120px",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus operatePush",
                                    class: {
                                      disabled: _vm.gridDataQty.length >= 9,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowAvailableQty("push")
                                      },
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-remove operateDel",
                                    class: {
                                      disabled: _vm.gridDataQty.length === 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearLastRowData(
                                          scope.$index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            property: "name",
                            label: "起始次数",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    staticClass: "inputWidth",
                                    attrs: { type: "number", width: "80px" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.validateAgeInputQty(
                                          scope.row,
                                          "beginQty"
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.beginQty,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "beginQty", $$v)
                                      },
                                      expression: "scope.row.beginQty",
                                    },
                                  }),
                                  _c("span", { staticClass: "marL10" }, [
                                    _vm._v("次"),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "至",
                            width: "60px",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            property: "address",
                            label: "结束次数",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    staticClass: "inputWidth",
                                    attrs: { type: "number", width: "80px" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.validateAgeInputQty(
                                          scope.row,
                                          "endQty"
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.endQty,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "endQty", $$v)
                                      },
                                      expression: "scope.row.endQty",
                                    },
                                  }),
                                  _c("span", { staticClass: "marL10" }, [
                                    _vm._v("次"),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogTableVisibleQty = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.updateQtyRangeAndFetchData },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("el-card", { staticClass: "marT10" }, [
        _c("div", [
          _c("div", { staticClass: "x-bc" }, [
            _c("div", { staticClass: "distributionTitleText" }, [
              _c("span", [_vm._v("会员增长分析")]),
            ]),
            _c("div", [
              _c(
                "div",
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      size: "mini",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.fetchOverviewData("vipIncrease")
                      },
                    },
                    model: {
                      value: _vm.pickerTimeAdd,
                      callback: function ($$v) {
                        _vm.pickerTimeAdd = $$v
                      },
                      expression: "pickerTimeAdd",
                    },
                  }),
                  _c("timeRange", {
                    attrs: {
                      format: "daterange",
                      "default-value": "week",
                      size: "mini",
                      data: ["week", "month", "year"],
                    },
                    on: { change: _vm.handleTimeRangeChangeAdd },
                    model: {
                      value: _vm.pickerTimeAdd,
                      callback: function ($$v) {
                        _vm.pickerTimeAdd = $$v
                      },
                      expression: "pickerTimeAdd",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "420px" },
            attrs: { id: "chart-containerAdd" },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }