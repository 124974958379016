<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  listExchangeMoneyAPI,
  listExchangeGoodsAPI,
  listExchangeCouponAPI,
  listExchangeMoneySummaryAPI,
  listExchangeGoodsSummaryAPI,
  listExchangeCouponSummaryAPI
} from '@/api/vip/report/exchangeQuery'

export default {
  name: 'exchangeQuery',
  components: { TablePage },
  data () {
    return {
      options: {
        summary: '',
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员兑换查询',
        rowKey: '',
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "filters",
            tip: "门店编码/门店名称/级别编码/级别名称/会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" }
            ]
          },
          this.$select({
            key: 'vipLevel',
            option: {
              filter: 'vipLevelIds',
              seniorSearch: true,
              option:{
                multiple:true
              }
            }
          }),
          {
            type: "numberRange",
            label: "积分范围",
            filter: "scoreBegin",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              scoreBegin: one,
              scoreEnd: two
            })
          },
          this.$select({
            key: 'vip',
            option: {
              filter: 'vipIds',
              seniorSearch: true,
              option:{
                multiple:true
              }
            }
          }),
          {
            type: 'input',
            label: '手机号',
            model: '',
            filter: 'tel',
            seniorSearch: true
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option:{
                multiple:true
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabsColumns:[
          {
            title:'会员兑换商品查询',
            getListApi: listExchangeGoodsAPI,
            getSummaryApi: listExchangeGoodsSummaryAPI,
            type:'exchangeGoodsList',
            exportOption: {
              exportApi: 'vipExchangeGoodsQuery',
              exportName: '会员兑换商品'
             },
            columns: [
              {
                prop: 'billDate',
                label: '兑换时间',
                minWidth: 150
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitQty',
                label: '兑换数量',
                minWidth: 150
              },
              {
                prop: 'consumeScore',
                label: '消耗积分',
                minWidth: 150
              },
            ],
            summary: ['unitQty','consumeScore']
          },
          {
            title:'会员兑换优惠券查询',
            getListApi: listExchangeCouponAPI,
            getSummaryApi: listExchangeCouponSummaryAPI,
            type:'exchangeCouponList',
            exportOption: {
              exportApi: 'vipExchangeCouponQuery',
              exportName: '会员兑换优惠券'
             },
            columns: [
              {
                prop: 'billDate',
                label: '兑换时间',
                minWidth: 150
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'couponCaseName',
                label: '优惠券名称',
                minWidth: 150
              },
              {
                prop: 'couponCaseNo',
                label: '优惠券号',
                minWidth: 150
              },
              {
                prop: 'couponCaseTypeName',
                label: '优惠券类型',
                minWidth: 150
              },
              {
                prop: 'couponQty',
                label: '兑换数量',
                minWidth: 150
              },
              {
                prop: 'consumeScore',
                label: '消耗积分',
                minWidth: 150
              },
            ],
            summary: ['couponQty','consumeScore']
          },
          {
            title:'会员兑换金额查询',
            getListApi: listExchangeMoneyAPI,
            getSummaryApi: listExchangeMoneySummaryAPI,
            type:'exchangeMoneyList',
            exportOption: {
              exportApi: 'vipExchangeMoneyQuery',
              exportName: '会员兑换金额'
             },
            columns: [
              {
                prop: 'billDate',
                label: '兑换时间',
                minWidth: 150
              },
              {
                prop: 'shopNo',
                label: '门店编号',
                minWidth: 150
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150
              },
              {
                prop: 'vipNo',
                label: '会员卡号',
                minWidth: 150
              },
              {
                prop: 'vipName',
                label: '会员名称',
                minWidth: 150
              },
              {
                prop: 'tel',
                label: '手机号',
                minWidth: 150
              },
              {
                prop: 'vipLevelNo',
                label: '级别编号',
                minWidth: 150
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 150
              },
              {
                prop: 'fillMoney',
                label: '兑换金额',
                minWidth: 150
              },
              {
                prop: 'consumeScore',
                label: '消耗积分',
                minWidth: 150
              },
            ],
            summary: ['fillMoney','consumeScore']

          }
        ],
      },
    }
  },
}
</script>