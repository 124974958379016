<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import { cardReplacementList } from '@/api/vip/report/cardChangeQuery' // 会员换卡
export default {
  name: 'cardChangeQuery',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员换卡查询',
        getListApi: cardReplacementList,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/vip/bill/cardReplacementListExport',
          exportName: '会员换卡查询'
        },
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '单据号' },
              { filter: 'oldVipNos', label: '旧会员卡号' },
              { filter: 'oldVipNames', label: '会员名称' },
              { filter: 'newVipNos', label: '新会员卡号' },
              { filter: 'vipLevelNos', label: '级别编码' },
              { filter: 'vipLevelNames', label: '级别名称' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'billNo',
            label: '单据号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'billDateStr',
            label: '单据日期',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'oldVipNo',
            label: '旧会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'oldVipName',
            label: '会员名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'oldCardStatusName',
            label: '旧卡状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'newVipNo',
            label: '新会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'cardChangeTypeName',
            label: '换卡类型',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'vipLevelNo',
            label: '级别编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelName',
            label: '级别名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ]
      }
    }
  }
}
</script>
