<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { getVipLoss,getVipLossSummary,getVipReportDefaultCondition } from '@/api/vip/report/lossQuery.js' //会员充值接口
export default {
  name: 'lossQuery',
  components: { TablePage },
  data () {
    return {
      options: {
        loading: true,
        listNo: true, // 序号
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        defaultBody: async () => {
          try{
            const { data }  = await getVipReportDefaultCondition(3)
            if(data){
              this.options.search[5].model = [Number(data.beg),Number(data.end)]
              return {
                startNotConsumptionDuration:Number(data.beg),
                endNotConsumptionDuration:Number(data.end)
              }
            }
          }catch(err){}
        },
        body: {}, // 请求内容
        getListApi: getVipLoss,
        getSummaryApi: getVipLossSummary,
        title: '会员流失查询',
        rowKey: 'vipNo',
        exportOption: {
          exportApi: 'vipLossQuery',
          exportName: '会员流失'
        },
        search: [
          {
            type: 'filters',
            tip: '级别编码/级别名称/会员卡号/会员名称/手机号',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipLevelNos', label: '级别编码' },
              { filter: 'vipLevelNames', label: '级别名称' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'tels', label: '手机号' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' },
          this.$select({
            key: 'viplevel',
            option: {
              filter: 'vipLevelIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          {
            type: 'numberRange',
            label: '未消费时长',
            filter: 'startNotConsumptionDuration',
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            labelWidth:90,
            filterFnc: ([one, two] = []) => ({
              startNotConsumptionDuration: one,
              endNotConsumptionDuration: two
            })
          }
        ],
        columns: [
          //会员卡号、会员姓名、手机、级别编号、级别名称、卡内总金额、可用积分、上次消费日期、未消费时长（天）
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 150
          },
          {
            prop: 'vipName',
            label: '会员姓名',
            minWidth: 120
          },
          {
            prop: 'tel',
            label: '手机号',
            minWidth: 110
          },
          {
            prop: 'vipLevelNo',
            label: '级别编号',
            minWidth: 110
          },
          {
            prop: 'vipLevelName',
            label: '级别名称',
            minWidth: 120
          },
          {
            prop: 'totalUsableMoney',
            label: '卡内总金额',
            minWidth: 100
          },
          {
            prop: 'usableScore',
            label: '可用积分',
            minWidth: 100
          },
          {
            prop: 'lastUseTime',
            label: '上次消费日期',
            minWidth: 110
          },
          {
            prop: 'notConsumptionDuration',
            label: '未消费时长（天）',
            minWidth: 120
          }
        ],
        summary: ['totalUsableMoney', 'usableScore', 'notConsumptionDuration']
      },
      shopInfo: [],
      tenantInfo: []
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'getListed':
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
