<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  plusCaseRevertAPI,
  plusCaseListAPI,
  plusCaseRemoveAPI,
  plusCaseIsStopAPI,
  plusCaseUpdateStatusAPI,
  plusCaseCopyAPI
} from '@/api/vip/plusVip/case' //  plus方案
export default {
  name: 'plusCase',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '单据列表',
        getListApi: plusCaseListAPI,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'caseId',
        //搜索
        search: [
          {
            type: 'filters',
            tip: '方案编号/方案名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'caseNos', label: '方案编号' },
              { filter: 'caseNames', label: '方案名称' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'audit',
            label: '审核',
            alertText: '确认要审核选中方案吗？',
            icon: 'el-icon-user',
            type: 'success',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'start',
            label: '启用',
            alertText: '确认要启用选中方案吗？',
            btnType: 'dropdown',
            other: [
              {
                click: 'stop',
                label: '禁用',
                alertText: '确认要禁用选中方案吗？'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'copy',
            label: '复制',
            type: 'success',
            icon: 'el-icon-document-copy',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            alertText: '确认要删除选中的方案吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: plusCaseRevertAPI,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'caseNo',
            label: '方案编号',
            type: 'link',
            click: 'update',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'caseName',
            label: '方案名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'oldPrice',
            label: '原价',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'salePrice',
            label: '售价',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'caseStatusName',
            label: '方案状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'isStop',
            label: '启用状态',
            formatter: (v, row) => (row.isStop ? '禁用' : '启用'),
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusVipValidDays',
            label: '有效天数',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusVipValidDaysTypeName',
            label: '展示单位',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      let caseIds = []
      switch (type) {
        case 'add':
          this.$router.push({
            name: 'caseDetail'
          })
          break
        case 'update':
          this.$router.push({
            name: 'caseDetail',
            query: {
              caseId: row.caseId,
              type: 'Update'
            }
          })
          break
        case 'audit':
          this.options.check.forEach(item => {
            if (item.caseStatus == '0') caseIds.push(item.caseId)
          })
          if (caseIds.length >= 1) {
            try {
              await plusCaseUpdateStatusAPI({
                caseIds,
                caseStatus: '1'
              })
              this.$message.success('审核成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未审核的方案')
          }
          break
        case 'start':
          caseIds = this.options.check
            .map(p => {
              if (p.caseStatus == '1' && p.isStop) {
                return p.caseId
              }
            })
            .filter(o => o != undefined)
          console.log('caseIdsl', caseIds)
          if (caseIds.length >= 1) {
            try {
              await plusCaseIsStopAPI({
                caseIds,
                isStop: false
              })
              this.$message.success('启用成功')
              await this.$refs.tablePage.getList()
              console.log('caseIds', this.options)
            } catch (err) {}
          } else {
            this.$message.error('请选择已审核未启用的方案')
          }
          break
        case 'stop':
          caseIds = this.options.check
            .map(p => {
              if (p.caseStatus == '1' && !p.isStop) {
                return p.caseId
              }
            })
            .filter(o => o != undefined)

          if (caseIds.length >= 1) {
            try {
              await plusCaseIsStopAPI({
                caseIds,
                isStop: true
              })
              this.$message.success('禁用成功')
              await this.$refs.tablePage.getList()
              console.log('caseIds', this.options)
            } catch (err) {}
          } else {
            this.$message.error('请选择已审核已启用的方案')
          }
          break
        case 'copy':
          console.log('check', this.options.check)
          caseIds = this.options.check.map(item => item.caseId)
          try {
            await plusCaseCopyAPI(caseIds)
            this.$message.success('复制成功')
            this.$refs.tablePage.getList()
          } catch (err) {}
          break
        case 'del':
          this.options.check.forEach(item => {
            if (item.caseStatus == '0') caseIds.push(item.caseId)
          })
          if (caseIds.length >= 1) {
            try {
              await plusCaseRemoveAPI(caseIds)
              this.$message.success('删除成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未审核的方案')
          }
          break
        default:
          break
      }
    }
  }
}
</script>
