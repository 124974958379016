<template>
  <div class="wrap" v-loading="loading">
    <topOperatingButton id="topOperatingButton" :isExamineBtn="true" :isAddBtn="showAdd" :showAudit="showAudit"
      :isAuditBillBtn="false" @submitForm="submitForm(true)" @addBill="addBill" @handleAdd="handleAdd"
      @getQuit="getQuit" @auditBill="auditBill">
    </topOperatingButton>
    <el-form ref="form" :model="caseForm" :rules="rules" label-width="140px">
      <cardTitleCom cardTitle="基本信息" :auditOrder="auditOrder" :noAuditOrder="noAuditOrder" :stopOrder="stopOrder"
        :deleteOrder="deleteOrder" :disableOrder="disableOrder">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-w ">
              <el-form-item label="方案编号" prop="caseNo">
                <el-input :disabled="true" class="inputWidth " v-model="caseForm.caseNo" placeholder="方案编号"
                  size="mini"></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="caseName">
                <el-input :disabled="disabled" class="inputWidth " v-model="caseForm.caseName" placeholder="方案名称"
                  size="mini" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="原价" prop="oldPrice">
                <el-input type="number" :disabled="disabled" class="inputWidth " v-model="caseForm.oldPrice"
                  placeholder="原价" size="mini" @input=" limitInputlength(caseForm.oldPrice, caseForm, 'oldPrice', true)"></el-input>
              </el-form-item>
              <el-form-item label="售价" prop="salePrice">
                <el-input type="number" :disabled="disabled" class="inputWidth " v-model="caseForm.salePrice"
                  placeholder="售价" size="mini"  @input="limitInputlength(caseForm.salePrice, caseForm, 'salePrice', true)"></el-input>
              </el-form-item>
              <el-form-item label="有限期天数" prop="plusVipValidDaysType">
                <el-select  v-model="caseForm.plusVipValidDaysType" placeholder="展示单位" style="width: 120px" filterable
                  :disabled="disabled" size="mini">
                  <el-option v-for="item in validityList" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label-width="0" prop="plusVipValidDays">
                <el-input  style="width: 130px" :disabled="disabled" v-model.number="caseForm.plusVipValidDays"
                  placeholder="天数" size="mini"  @input="limitInputlength(caseForm.plusVipValidDays, caseForm, 'plusVipValidDays', true)"></el-input>
              </el-form-item>
              <el-form-item label="PLUS会员级别" prop="plusVipLevelId">
                <SelectRemote  v-model="caseForm.plusVipLevelId" style="width: 250px" :option="
                    $select({
                      key: 'viplevel',
                      option: {
                        option: {
                          disabled: disabled
                        }
                      }
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="限制购买次数" prop="limitBuyCount">
                <el-input  maxlength="4"  :disabled="disabled" class="inputWidth " v-model.number="caseForm.limitBuyCount"
                  placeholder="限制购买次数" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="限制使用总次数" prop="limitSumCount">
                <el-input  maxlength="4"  :disabled="disabled" class="inputWidth " v-model.number="caseForm.limitSumCount"
                  placeholder="限制使用总次数" size="mini"></el-input>
              </el-form-item>
              <div class="x-f">
                <el-form-item label="限制使用次数" prop="limitDayCountType">
                  <el-select  v-model="caseForm.limitDayCountType" placeholder="展示单位" style="width: 120px" filterable
                    :disabled="disabled" size="mini">
                    <el-option v-for="item in validityList" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label-width="0" prop="limitDayCount">
                  <el-input  style="width: 130px"  :disabled="disabled" v-model.number="caseForm.limitDayCount"
                    placeholder="次数" size="mini" @input="limitInputlength(caseForm.limitDayCount, caseForm, 'limitDayCount', true)"></el-input>
                </el-form-item>
            </div>
            </div>
            <div class="marB10">
              <el-form-item label="适用级别">
                <el-radio-group v-model="caseForm.vipLevelRangeType" :disabled="disabled">
                  <el-radio v-for="dict in dict.type.fill_vip_level_range_type" :key="dict.value"
                    :label="parseInt(dict.value)">{{ dict.label }}</el-radio>
                </el-radio-group>
                <div v-show="
                    caseForm.vipLevelRangeType == 2 ||
                    caseForm.vipLevelRangeType == 1
                  ">
                  <el-button size="mini" @click="handleEvent('openVipLevelDialog')"
                    :disabled="disabled">指定会员等级</el-button>
                  <!-- 可编辑表格 -->
                  <EditTable :options="vipLevelOptions" />
                </div>
              </el-form-item>
              <el-form-item label="PLUS会员权益">
                <div class="marT10">
                  <el-upload v-show="false" class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
                    :on-success="uploadSuccess" />
                  <quill-editor :disabled="disabled" class="marB20" v-model="caseForm.caseContent" ref="QuillEditor" :options="editorOption"
                    @change="onEditorChange($event)">
                  </quill-editor>
                </div>
              </el-form-item>
              <el-form-item label="PLUS会员规则" prop="remark">
                <el-input :disabled="disabled" v-model="caseForm.remark" type="textarea" placeholder="请输入PLUS会员规则"
                  maxlength="600" :autosize="{ minRows: 1, maxRows: 8 }" />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="购买赠送">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 第一行 -->
            <div class="marB20">
              <el-checkbox v-model="caseForm.isUseAfterDaysLargess1" :disabled="disabled"></el-checkbox>
              <span class="fS14MR10 marL10">购买</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.afterDays1" class="inputWidth marR10"
                size="mini"></el-input>
              <span class="fS14MR10">天后，</span>
              <span class="fS14MR10">赠送优惠券</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponCaseId1Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenCoupon(1)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponCaseId1" style="width: 250px" class="inputWidth marR15"
                :disabled="disabled" :option="
                  $select({
                    key: 'listCoupon',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: { isStops: [false] }
                      }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送张数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponCount1" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays1" class="inputWidth"
                size="mini">
              </el-input>
            </div>
            <!-- 第二行 -->
            <div class="marB20">
              <el-checkbox v-model="caseForm.isUseAfterDaysLargess2" :disabled="disabled"></el-checkbox>
              <span class="fS14MR10 marL10">购买</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.afterDays2" class="inputWidth marR10"
                size="mini"></el-input>
              <span class="fS14MR10">天后，</span>
              <span class="fS14MR10 giftVoucherPackDay">赠送券包</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponPackageId2Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenPackage(2)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponPackageId2" style="width: 250px" class="inputWidth marR15"
                :disabled="disabled" :option="
                  $select({
                    key: 'listCouponPackage',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: { isStops: 1 }
                      }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送个数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponPackageCount2" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays2" class="inputWidth"
                size="mini">
              </el-input>
            </div>
            <!-- 第三行 -->
            <div class="marB20 x-f">
              <el-checkbox :disabled="disabled" v-model="caseForm.isUseEveryMonthLargess3"></el-checkbox>
              <span class="fS14MR10 marL10">每月</span>
              <div>
                <SelectLocal :multiple="true" class="marR15" :disabled="disabled" v-model="caseForm.arrEveryMonthStr3"
                  style="width: 250px" :option="{
                    data: monthList,
                    clearable: true,
                    value: 'value',
                    label: 'label'
                  }" />
              </div>
              <span class="fS14MR10">号，</span>
              <span class="fS14MR10 marL10">赠送优惠券</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponCaseId3Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenCoupon(3)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponCaseId3" style="width: 250px" class="marR15" :disabled="disabled"
                :option="
                  $select({
                    key: 'listCoupon',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: { isStops: [false] }
                      }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送张数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponCount3" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays3" class="inputWidth"
                size="mini">
              </el-input>
            </div>
            <!-- 第四行 -->
            <div class="marB20 x-f">
              <el-checkbox :disabled="disabled" v-model="caseForm.isUseEveryMonthLargess4"></el-checkbox>
              <span class="fS14MR10 marL10">每月</span>
              <div>
                <SelectLocal :multiple="true" :disabled="disabled" v-model="caseForm.arrEveryMonthStr4"
                  style="width: 250px" class="marR15" :option="{
                    data: monthList,
                    clearable: true,
                    value: 'value',
                    label: 'label'
                  }" />
              </div>
              <span class="fS14MR10">号，</span>
              <span class="fS14MR10 giftVoucherPack">赠送券包</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponPackageId4Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenPackage(4)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponPackageId4" style="width: 250px" class="inputWidth marR15"
                :disabled="disabled" :option="
                  $select({
                    key: 'listCouponPackage',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: { isStops: [1] }
                      }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送个数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponPackageCount4" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays4" class="inputWidth"
                size="mini">
              </el-input>
            </div>
            <!-- 第五行 -->
            <div class="marB20">
              <el-checkbox :disabled="disabled" v-model="caseForm.isUseEveryWeekLargess5"></el-checkbox>
              <span class="fS14MR10 marL10">每周</span>
              <SelectLocal :multiple="true" class="marR15" :disabled="disabled" v-model="caseForm.arrEveryWeekStr5"
                style="width: 250px" :option="{
                  data: weekList,
                  clearable: true,
                  value: 'value',
                  label: 'label'
                }" />
              <span class="fS14MR10 giftVoucherPackCoupon">赠送优惠券</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponCaseId5Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenCoupon(5)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponCaseId5" style="width: 250px" class="inputWidth marR15"
                :disabled="disabled" :option="
                  $select({
                    key: 'listCoupon',
                    option: {
                      option: {
                        clearable: true,
                        remoteBody: { isStops: [false] }
                      }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送张数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponCount5" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays5" class="inputWidth"
                size="mini">
              </el-input>
            </div>
            <!-- 第六行 -->
            <div class="marB20">
              <el-checkbox :disabled="disabled" v-model="caseForm.isUseEveryWeekLargess6"></el-checkbox>
              <span class="fontS14 marL10">每周</span>
              <SelectLocal :multiple="true" :disabled="disabled" v-model="caseForm.arrEveryWeekStr6" class="marL10"
                style="width: 250px" :option="{
                  data: weekList,
                  clearable: true,
                  value: 'value',
                  label: 'label'
                }" />
              <span class="fS14MR10 giftVoucherPackWeek">赠送券包</span>
              <!-- <el-input
                :disabled="disabled"
                readonly
                v-model="caseForm.couponPackageId6Name"
                class="inputWidth marR10"
                size="mini"
              >
                <i
                  slot="suffix"
                  class="el-icon-more more marT10"
                  @click="getOpenPackage(6)"
                ></i>
              </el-input> -->
              <!-- 远程数据选择器 -->
              <SelectRemote v-model="caseForm.couponPackageId6" style="width: 250px" class="inputWidth marR15"
                :disabled="disabled" :option="
                  $select({
                    key: 'listCouponPackage',
                    option: {
                      option: { clearable: true, remoteBody: { isStops: [1] } }
                    }
                  }).option
                " />
              <span class="fS14MR10">赠送个数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponPackageCount6" class="inputWidth"
                size="mini">
              </el-input>
              <span class="fS14MR10 marL10">有效天数</span>
              <el-input type="number" :disabled="disabled" v-model="caseForm.couponValidDays6" class="inputWidth"
                size="mini">
              </el-input>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择优惠券对话框 -->
    <selectCoupon :isRadio="true" :OpenCoupon.sync="openCoupon" @getCoupons="getPitchData($event, 'coupon')">
    </selectCoupon>
    <!-- 券包 -->
    <selectStorePackage :isSelectPackageRadio="true" :OpenPackage.sync="openPackage" @packageItemCom="packageItemCom" />
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { limitInputlength } from "@/utils";
import { uniqWith } from 'lodash' //去重
import Dialog from '@/components/Dialog'
import EditTable from '@/components/editTable' //可编辑表格
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import { CouponList } from '@/api/marketing/coupon/case' //搜索优惠券接口api
import selectStorePackage from '@/views/components/selectStorePackage' //  券包组件
import { packageListAPI } from '@/api/marketing/coupon/package' //优惠券包接口
import { getBillNo } from '@/api/codeRule' //方案号
import {
  plusCaseAddAPI,
  plusCaseDetailAPI,
  plusCaseUpdateAPI,
  plusCaseIsStopAPI,
  plusCaseUpdateStatusAPI
} from '@/api/vip/plusVip/case' //  plus方案
export default {
  name: 'caseDetail',
  dicts: ['fill_vip_level_range_type'],
  components: {
    cardTitleCom,
    selectCoupon,
    selectStorePackage,
    topOperatingButton,
    SelectLocal,
    SelectRemote,
    quillEditor,
    EditTable,
    Dialog
  },
  data() {
    return {
      validityList: [
        {
          label: '日',
          value: 1
        },
        {
          label: '周',
          value: 2
        },
        {
          label: '月',
          value: 3
        },
        {
          label: '季',
          value: 4
        },
        {
          label: '年',
          value: 5
        }
      ],
      //弹窗配置
      dialogOptions: {
        curType: '', //当前类型(门店/会员/优惠券)
        title: '选择会员',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      //会员级别表格配置
      vipLevelOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'vipLevelNo',
            label: '级别编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelName',
            label: '级别名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: '',
            label: '',
            minWidth: 150,
            align: 'center'
          }
        ]
      },
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/open/oss/file/upload', // 服务器上传地址
      editorOption: {
        placeholder: '请输入商品详情',
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'] // 链接、图片、视频
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }, //上传富文本
      //优惠券
      couponList: [],
      //优惠券包
      packageList: [],
      //标识已审核
      auditOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      showAudit: true, //审核按钮禁用状态
      showAdd: false, //新增按钮
      disabled: false, //保存禁用
      loadingTable: false, //表格遮罩
      loading: false, //大盒子遮罩层
      //选择优惠券开关
      openCoupon: false,
      //优惠券下标
      couponIndex: undefined,
      //券包下标
      packageIndex: undefined,
      //券包对话框
      openPackage: false,
      monthList: [
        { label: '1号', value: '1' },
        { label: '2号', value: '2' },
        { label: '3号', value: '3' },
        { label: '4号', value: '4' },
        { label: '5号', value: '5' },
        { label: '6号', value: '6' },
        { label: '7号', value: '7' },
        { label: '8号', value: '8' },
        { label: '9号', value: '9' },
        { label: '10号', value: '10' },
        { label: '11号', value: '11' },
        { label: '12号', value: '12' },
        { label: '13号', value: '13' },
        { label: '14号', value: '14' },
        { label: '15号', value: '15' },
        { label: '16号', value: '16' },
        { label: '17号', value: '17' },
        { label: '18号', value: '18' },
        { label: '19号', value: '19' },
        { label: '20号', value: '20' },
        { label: '21号', value: '21' },
        { label: '22号', value: '22' },
        { label: '23号', value: '23' },
        { label: '24号', value: '24' },
        { label: '25号', value: '25' },
        { label: '26号', value: '26' },
        { label: '27号', value: '27' },
        { label: '28号', value: '28' },
        { label: '29号', value: '29' },
        { label: '30号', value: '30' },
        { label: '31号', value: '31' }
      ],
      weekList: [
        { label: '星期一', value: '1' },
        { label: '星期二', value: '2' },
        { label: '星期三', value: '3' },
        { label: '星期四', value: '4' },
        { label: '星期五', value: '5' },
        { label: '星期六', value: '6' },
        { label: '星期七', value: '7' }
      ],
      selectedDates: [],
      openCoupon: false, //优惠券
      //旧的表单
      oldForm: {},
      //plus会员方案表单
      caseForm: {
        caseStatus: '',
        isUseAfterDaysLargess1: false, //多少天后赠送1 是否启用
        isUseAfterDaysLargess2: false, //多少天后赠送2 是否启用
        isUseEveryMonthLargess3: false, //每月赠送3 是否启用
        isUseEveryMonthLargess4: false, //每月赠送4 是否启用
        isUseEveryWeekLargess5: false, //每周赠送5 是否启用
        isUseEveryWeekLargess6: false, //每周赠送6 是否启用
        arrEveryMonthStr3: [], //每月赠送3 哪些日数组
        arrEveryMonthStr4: [], //每月赠送4 哪些日数组
        arrEveryWeekStr5: [], //每周赠送5 哪些星期数组
        arrEveryWeekStr6: [], //每周赠送6 哪些星期数组
        vipLevelRangeType: 0,//适用级别
        plusVipValidDaysType: 1, //展示单位
        limitDayCountType: 1 //展示单位
      },
      //plus会员方案表单校验
      rules: {
        caseNo: [
          {
            required: true,
            message: '请到编码规则配置单据类型',
            trigger: ['blur', 'change']
          }
        ],
        caseName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        oldPrice: [
          {
            required: true,
            message: '原价不能为空',
            trigger: ['blur', 'change']
          }
        ],
        salePrice: [
          {
            required: true,
            message: '售价不能为空',
            trigger: ['blur', 'change']
          }
        ],
        plusVipValidDaysType: [
          {
            required: true,
            message: '展示单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        plusVipValidDays: [
          {
            required: true,
            message: '有效期天数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        plusVipLevelId: [
          {
            required: true,
            message: '会员级别不能为空',
            trigger: ['blur', 'change']
          }
        ],
         limitBuyCount: [
          {
            required: true,
            message: '限制购买次数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        limitSumCount: [
          {
            required: true,
            message: '限制使用总次数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        limitDayCountType: [
          {
            required: true,
            message: '展示单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        limitDayCount: [
          {
            required: true,
            message: '限制使用次数不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      //优惠券
      const res1 = await CouponList()
      vm.couponList = res1.rows
      //优惠券包
      const res2 = await packageListAPI()
      vm.packageList = res2.rows
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.caseId) {
          //获取方案详情
          const { data } = await plusCaseDetailAPI(vm.$route.query.caseId)
          //方案赋值
          vm.caseForm = data
          vm.vipLevelOptions.list = vm.caseForm.vipPlusCaseVipLevelItems || []
          //优惠券
          if (vm.couponList.length > 0) {
            Object.keys(vm.caseForm).forEach(key => {
              vm.couponList.forEach(v => {
                if (v.couponCaseId == vm.caseForm.couponCaseId1) {
                  vm.$set(vm.caseForm, 'couponCaseId1Name', v.couponCaseName)
                }
                if (v.couponCaseId == vm.caseForm.couponCaseId3) {
                  vm.$set(vm.caseForm, 'couponCaseId3Name', v.couponCaseName)
                }
                if (v.couponCaseId == vm.caseForm.couponCaseId5) {
                  vm.$set(vm.caseForm, 'couponCaseId5Name', v.couponCaseName)
                }
              })
            })
          }
          //优惠券包
          if (vm.couponList.length > 0) {
            Object.keys(vm.caseForm).forEach(key => {
              vm.packageList.forEach(v => {
                if (v.packageId == vm.caseForm.couponPackageId2) {
                  vm.$set(vm.caseForm, 'couponPackageId2Name', v.packageName)
                }
                if (v.packageId == vm.caseForm.couponPackageId4) {
                  vm.$set(vm.caseForm, 'couponPackageId4Name', v.packageName)
                }
                if (v.packageId == vm.caseForm.couponPackageId6) {
                  vm.$set(vm.caseForm, 'couponPackageId6Name', v.packageName)
                }
              })
            })
          }
          //原始方案赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.caseForm))
        }
      } else {
        await vm.getOrderBillNo()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.caseForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.caseForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.caseForm.caseId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.caseForm))
    }
    next()
  },
  watch: {
    'caseForm.billStatus': {
      handler(val) {
        const disabledColumns = ['2', '3'].includes(val)
        this.vipLevelOptions.status = disabledColumns
        // this.disabled = disabledColumns
      },
      immediate: true
    },
    //侦听单据状态
    'caseForm.caseStatus': {
      handler(newVal) {
        if (this.caseForm.caseStatus == '1') {
          //新增按钮
          this.showAdd = true
          //打开审核图标
          this.auditOrder = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = true
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
        } else if (this.caseForm.caseStatus == '0') {
          //新增按钮
          this.showAdd = false
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
          //关闭审核图标
          this.auditOrder = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //恢复全部输入
          this.disabled = false
        } else if (
          this.caseForm.caseStatus == '' ||
          !this.caseForm.caseStatus
        ) {
          //引入源单按钮
          this.showImportBill = true
          //新增按钮
          this.showAdd = false
          //关闭已禁用图标
          this.disableOrder = false
          //关闭关闭图标
          this.stopOrder = false
          //关闭已删除图标
          this.deleteOrder = false
          //关闭审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    }
  },

  methods: {
    limitInputlength,
    async handleEvent(type, row) {
      switch (type) {
        case 'openVipLevelDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'vipLevelOptions',
            curType: 'vipLevelId', //当前类型(会员/门店/优惠券)
            title: '选择会员',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'vipLevel' })
          }
          break
        case 'dialogChange':
          let list = []
          let curTable = this.dialogOptions.curTable
          let curType = this.dialogOptions.curType
          if (this.dialogOptions.formData.table.check.length <= 0) return
          list = this.dialogOptions.formData.table.check
          list = [...list, ...this[curTable].list]
          list = uniqWith(list, (x, y) => x[curType] == y[curType])
          if (curType == 'couponCaseId')
            list = list.map(item => ({
              ...item,
              largessCouponCaseId: item.couponCaseId
            }))
          this[curTable].list = list
          break
        default:
          break
      }
    },
    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      this.content = html
    },
    uploadSuccess(res) {
      // 获取富文本组件实例
      console.log('uploadSuccess', res)
      try {
        let quill = (this.$refs.QuillEditor[0] || this.$refs.QuillEditor).quill
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', res.data.accessUrl)
          // 调整光标到最后
          quill.setSelection(length + 1)
          return true
        }
      } catch (error) { }
      this.$message.error('图片插入失败！')
    },
    //优惠券确认
    getPitchData(value) {
      // this.caseForm.marketingCouponPackageDetails = value

      if (this.couponIndex == 1) {
        this.caseForm.couponCaseId1Name = value[0].couponCaseName
        this.caseForm.couponCaseId1 = value[0].couponCaseId
      } else if (this.couponIndex == 3) {
        this.caseForm.couponCaseId3Name = value[0].couponCaseName
        this.caseForm.couponCaseId3 = value[0].couponCaseId
      } else if (this.couponIndex == 5) {
        this.caseForm.couponCaseId5Name = value[0].couponCaseName
        this.caseForm.couponCaseId5 = value[0].couponCaseId
      }
    },
    //优惠券弹窗
    getOpenCoupon(index) {
      this.openCoupon = true
      //优惠券的下标
      this.couponIndex = index
    },
    //券包确认
    packageItemCom(value) {
      if (this.packageIndex == 2) {
        this.caseForm.couponPackageId2Name = value.packageName
        this.caseForm.couponPackageId2 = value.packageId
      } else if (this.packageIndex == 4) {
        this.caseForm.couponPackageId4Name = value.packageName
        this.caseForm.couponPackageId4 = value.packageId
      } else if (this.packageIndex == 6) {
        this.caseForm.couponPackageId6Name = value.packageName
        this.caseForm.couponPackageId6 = value.packageId
      }
    },
    //券包弹窗
    getOpenPackage(index) {
      this.openPackage = true
      //券包的下标
      this.packageIndex = index
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          console.log('caseForm', this.caseForm)
            //限制购买次数
          if (this.caseForm.limitBuyCount>9999) {
               this.$message.error('限制购买次数不能大于9999')
              return
            }
              //限制使用总次数
          if (this.caseForm.limitSumCount>9999) {
               this.$message.error('限制使用总次数不能大于9999')
              return
            }
          //多少天后赠送1 是否启用
          if (this.caseForm.isUseAfterDaysLargess1) {
            //多少天后赠送1 多少天
            if (this.caseForm.afterDays1 == undefined) {
              this.$message.error('请设置赠送条件:购买多少天后赠送1')
              return
            }
            //多少天后赠送1 送券方案ID
            if (!this.caseForm.couponCaseId1) {
              this.$message.error('请设置赠送条件:赠送优惠券1')
              return
            }
            //多少天后赠送1 优惠券数量
            if (!this.caseForm.couponCount1) {
              this.$message.error('请设置赠送条件:赠送优惠券数量1')
              return
            }
          }
          //多少天后赠送2 是否启用
          if (this.caseForm.isUseAfterDaysLargess2) {
            //多少天后赠送2 多少天
            if (this.caseForm.afterDays2 == undefined) {
              this.$message.error('请设置赠送条件:购买多少天后赠送2')
              return
            }
            //多少天后赠送2 送券包ID
            if (!this.caseForm.couponPackageId2) {
              this.$message.error('请设置赠送条件:赠送优惠券包2')
              return
            }
            //多少天后赠送2 券包数量
            if (!this.caseForm.couponPackageCount2) {
              this.$message.error('请设置赠送条件:赠送优惠券包数量2')
              return
            }
          }
          //每月赠送3 是否启用
          if (this.caseForm.isUseEveryMonthLargess3) {
            //每月赠送3 哪些日数组
            if (this.caseForm.arrEveryMonthStr3.length <= 0) {
              this.$message.error('请设置赠送条件:每月几号赠送3')
              return
            }
            //每月赠送3 券方案ID
            if (!this.caseForm.couponCaseId3) {
              this.$message.error('请设置赠送条件:每月赠送优惠券3')
              return
            }
            //每月赠送3 优惠券数量
            if (!this.caseForm.couponCount3) {
              this.$message.error('请设置赠送条件:每月赠送优惠券数量3')
              return
            }
          }
          //每月赠送4 哪些日数组
          if (this.caseForm.isUseEveryMonthLargess4) {
            //每月赠送4 哪些日数组
            if (this.caseForm.arrEveryMonthStr4.length <= 0) {
              this.$message.error('请设置赠送条件:每月几号赠送4')
              return
            }
            //每月赠送4 送券包ID
            if (!this.caseForm.couponPackageId4) {
              this.$message.error('请设置赠送条件:每月赠送优惠券包4')
              return
            }
            //每月赠送4 券包数量
            if (!this.caseForm.couponPackageCount4) {
              this.$message.error('请设置赠送条件:每月赠送优惠券包数量4')
              return
            }
          }

          //每周赠送5 哪些星期数组
          if (this.caseForm.isUseEveryWeekLargess5) {
            //每周赠送5 哪些星期数组
            if (this.caseForm.arrEveryWeekStr5.length <= 0) {
              this.$message.error('请设置赠送条件:每周几赠送5')
              return
            }
            //每周赠送5 券方案ID
            if (!this.caseForm.couponCaseId5) {
              this.$message.error('请设置赠送条件:每周几赠送优惠券5')
              return
            }
            //每周赠送5 优惠券数量
            if (!this.caseForm.couponCount5) {
              this.$message.error('请设置赠送条件:每周几赠送优惠券数量5')
              return
            }
          }
          //每周赠送6 哪些星期数组
          if (this.caseForm.isUseEveryWeekLargess6) {
            //每周赠送6 哪些星期数组
            if (this.caseForm.arrEveryWeekStr6.length <= 0) {
              this.$message.error('请设置赠送条件:每周几赠送6')
              return
            }
            //每周赠送6 送券包ID
            if (!this.caseForm.couponPackageId6) {
              this.$message.error('请设置赠送条件:每周几赠送优惠券包6')
              return
            }
            //每周赠送6 券包数量
            if (!this.caseForm.couponPackageCount6) {
              this.$message.error('请设置赠送条件:每周几赠送优惠券包数量6')
              return
            }
          }
          this.caseForm = {
            ...this.caseForm,
            vipPlusCaseVipLevelItems: this.vipLevelOptions.list
          }
          if (this.caseForm.caseId) {
            const res = await plusCaseUpdateAPI(this.caseForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.caseForm = res.data
              //数据转换
              this.getDataConversion()
              this.$modal.msgSuccess('修改方案成功')
            } else {
              this.$modal.msgSuccess('保存并修改方案成功')
            }
          } else {
            const { data } = await plusCaseAddAPI(this.caseForm)
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.caseForm = data
              //数据转换
              this.getDataConversion()
              this.$modal.msgSuccess('新增方案成功')
            } else {
              this.$modal.msgSuccess('保存并新增方案成功')
            }
          }
        }
      })
    },
    // 表单重置
    reset() {
      //新增按钮
      this.showAdd = false
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭已禁用图标
      this.disableOrder = false
      //保存禁用
      this.disabled = false
      this.caseForm = {
        caseStatus: '',
        isUseAfterDaysLargess1: false, //多少天后赠送1 是否启用
        isUseAfterDaysLargess2: false, //多少天后赠送2 是否启用
        isUseEveryMonthLargess3: false, //每月赠送3 是否启用
        isUseEveryMonthLargess4: false, //每月赠送4 是否启用
        isUseEveryWeekLargess5: false, //每周赠送5 是否启用
        isUseEveryWeekLargess6: false, //每周赠送6 是否启用
        arrEveryMonthStr3: [], //每月赠送3 哪些日数组
        arrEveryMonthStr4: [], //每月赠送4 哪些日数组
        arrEveryWeekStr5: [], //每周赠送5 哪些星期数组
        arrEveryWeekStr6: [], //每周赠送6 哪些星期数组
        vipLevelRangeType: 0, //适用级别
        plusVipValidDaysType: 1, //展示单位
        limitDayCountType: 1, //展示单位
      }
      this.vipLevelOptions.list = []
      this.resetForm('form')
    },
    //数据转换
    getDataConversion() {
      //优惠券
      if (this.couponList.length > 0) {
        Object.keys(this.caseForm).forEach(key => {
          this.couponList.forEach(v => {
            if (v.couponCaseId == this.caseForm.couponCaseId1) {
              this.$set(this.caseForm, 'couponCaseId1Name', v.couponCaseName)
            }
            if (v.couponCaseId == this.caseForm.couponCaseId3) {
              this.$set(this.caseForm, 'couponCaseId3Name', v.couponCaseName)
            }
            if (v.couponCaseId == this.caseForm.couponCaseId5) {
              this.$set(this.caseForm, 'couponCaseId5Name', v.couponCaseName)
            }
          })
        })
      }
      //优惠券包
      if (this.couponList.length > 0) {
        Object.keys(this.caseForm).forEach(key => {
          this.packageList.forEach(v => {
            if (v.packageId == this.caseForm.couponPackageId2) {
              this.$set(this.caseForm, 'couponPackageId2Name', v.packageName)
            }
            if (v.packageId == this.caseForm.couponPackageId4) {
              this.$set(this.caseForm, 'couponPackageId4Name', v.packageName)
            }
            if (v.packageId == this.caseForm.couponPackageId6) {
              this.$set(this.caseForm, 'couponPackageId6Name', v.packageName)
            }
          })
        })
      }
    },

    //审核/删除/终止事件
    async auditBill(name) {
      if (name === '审核') {
        console.log('审核', this.caseForm)
        if (this.caseForm.caseStatus != '0') {
          this.$message.error('此单据不是未审核的状态哦~')
          return
        }
        const obj = {
          caseIds: [this.caseForm.caseId],
          caseStatus: '1'
        }
        //发送审核api
        const { data } = await plusCaseUpdateStatusAPI(obj)
        this.caseForm = data
        // 数据转换
        this.getDataConversion()
      }
      try {
        await this.$confirm(`此单据已审核，是否要启用?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        const obj = {
          caseIds: [this.caseForm.caseId],
          isStop: false
        }
        await plusCaseIsStopAPI(obj)
        // 弹出提示
        this.$message.success('审核并启用成功')
      } catch {
        // 弹出提示
        this.$message.success('审核成功')
      }
    },
    //新增按钮
    async handleAdd() {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      // await this.getInitializeData()
      this.loading = false
    },
    //保存并新增方案
    async addBill() {
      this.$confirm('是否保存并新增方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          this.loading = true
          this.submitForm(false)
          this.reset()
          await this.getOrderBillNo()
          this.loading = false
        })
        .catch()
    },
    //方案编号
    async getOrderBillNo() {
      this.caseForm.caseNo = await getBillNo(150301)
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/vipcard/plusVip/case" });
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  .inputWidth {
    width: 250px;
  }
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //内容
  .tableContent {
    width: 100%;
    padding: 6px 10px;
    //有效期
    .datetime {
      width: 170px;
    }
    //天赠送券包
    .giftVoucherPackDay {
      margin-left: 15px;
    }
    //月赠送券包
    .giftVoucherPack {
      margin-left: 24px;
    }
    //周赠送券包
    .giftVoucherPackWeek {
      margin-left: 75px;
    }
    //周赠送优惠券
    .giftVoucherPackCoupon {
      margin-left: 47px;
    }
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px !important;
}
//阿里图标
::v-deep .iconfont {
  font-size: 12px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #333333 !important;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 170px;
}
::v-deep .ql-editor {
  height: 400px;
}
</style>
