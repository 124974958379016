<template>
  <vipViewComponent />
</template>

<script>
import vipViewComponent from './components'
export default {
  name: 'vipView',
  components: { vipViewComponent }
}
</script>
