var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap x-fc" }, [
    _c(
      "div",
      { staticClass: "y-c distance" },
      [
        _c("el-image", {
          staticClass: "cursorP",
          staticStyle: { width: "160px", height: "130px" },
          attrs: {
            src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/f2d6b70b54334edfa81471cbd0fbc959.png",
          },
          on: { click: _vm.publicAccount },
        }),
        _c("span", { staticClass: "marB10" }, [_vm._v("已有服务号")]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.publicAccount },
          },
          [_vm._v("立即绑定")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "y-c" },
      [
        _c("el-image", {
          staticClass: "cursorP",
          staticStyle: { width: "160px", height: "130px" },
          attrs: {
            src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/fdf2b8d6b1044dfdb57f28107c98a3bd.png",
          },
          on: {
            click: function ($event) {
              return _vm.noPublicAccount("https://mp.weixin.qq.com/")
            },
          },
        }),
        _c("span", { staticClass: "marB10" }, [_vm._v("没有服务号")]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "mini" },
            on: {
              click: function ($event) {
                return _vm.noPublicAccount("https://mp.weixin.qq.com/")
              },
            },
          },
          [_vm._v("立即申请")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }