var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("topOperatingButton", {
        attrs: {
          id: "topOperatingButton",
          isExamineBtn: true,
          isAddBtn: _vm.showAdd,
          showAudit: _vm.showAudit,
          isAuditBillBtn: false,
        },
        on: {
          submitForm: function ($event) {
            return _vm.submitForm(true)
          },
          addBill: _vm.addBill,
          handleAdd: _vm.handleAdd,
          getQuit: _vm.getQuit,
          auditBill: _vm.auditBill,
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.caseForm,
            rules: _vm.rules,
            "label-width": "140px",
          },
        },
        [
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                auditOrder: _vm.auditOrder,
                noAuditOrder: _vm.noAuditOrder,
                stopOrder: _vm.stopOrder,
                deleteOrder: _vm.deleteOrder,
                disableOrder: _vm.disableOrder,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-w " },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案编号", prop: "caseNo" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              disabled: true,
                              placeholder: "方案编号",
                              size: "mini",
                            },
                            model: {
                              value: _vm.caseForm.caseNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "caseNo", $$v)
                              },
                              expression: "caseForm.caseNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案名称", prop: "caseName" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "方案名称",
                              size: "mini",
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.caseForm.caseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "caseName", $$v)
                              },
                              expression: "caseForm.caseName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "原价", prop: "oldPrice" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              type: "number",
                              disabled: _vm.disabled,
                              placeholder: "原价",
                              size: "mini",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.caseForm.oldPrice,
                                  _vm.caseForm,
                                  "oldPrice",
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.caseForm.oldPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "oldPrice", $$v)
                              },
                              expression: "caseForm.oldPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "售价", prop: "salePrice" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              type: "number",
                              disabled: _vm.disabled,
                              placeholder: "售价",
                              size: "mini",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.caseForm.salePrice,
                                  _vm.caseForm,
                                  "salePrice",
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.caseForm.salePrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "salePrice", $$v)
                              },
                              expression: "caseForm.salePrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "有限期天数",
                            prop: "plusVipValidDaysType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: "展示单位",
                                filterable: "",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              model: {
                                value: _vm.caseForm.plusVipValidDaysType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.caseForm,
                                    "plusVipValidDaysType",
                                    $$v
                                  )
                                },
                                expression: "caseForm.plusVipValidDaysType",
                              },
                            },
                            _vm._l(_vm.validityList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "0",
                            prop: "plusVipValidDays",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "130px" },
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "天数",
                              size: "mini",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.caseForm.plusVipValidDays,
                                  _vm.caseForm,
                                  "plusVipValidDays",
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.caseForm.plusVipValidDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.caseForm,
                                  "plusVipValidDays",
                                  _vm._n($$v)
                                )
                              },
                              expression: "caseForm.plusVipValidDays",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "PLUS会员级别",
                            prop: "plusVipLevelId",
                          },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              option: _vm.$select({
                                key: "viplevel",
                                option: {
                                  option: {
                                    disabled: _vm.disabled,
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.caseForm.plusVipLevelId,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "plusVipLevelId", $$v)
                              },
                              expression: "caseForm.plusVipLevelId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限制购买次数",
                            prop: "limitBuyCount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              maxlength: "4",
                              disabled: _vm.disabled,
                              placeholder: "限制购买次数",
                              size: "mini",
                            },
                            model: {
                              value: _vm.caseForm.limitBuyCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.caseForm,
                                  "limitBuyCount",
                                  _vm._n($$v)
                                )
                              },
                              expression: "caseForm.limitBuyCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限制使用总次数",
                            prop: "limitSumCount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth ",
                            attrs: {
                              maxlength: "4",
                              disabled: _vm.disabled,
                              placeholder: "限制使用总次数",
                              size: "mini",
                            },
                            model: {
                              value: _vm.caseForm.limitSumCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.caseForm,
                                  "limitSumCount",
                                  _vm._n($$v)
                                )
                              },
                              expression: "caseForm.limitSumCount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "限制使用次数",
                                prop: "limitDayCountType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    placeholder: "展示单位",
                                    filterable: "",
                                    disabled: _vm.disabled,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.caseForm.limitDayCountType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.caseForm,
                                        "limitDayCountType",
                                        $$v
                                      )
                                    },
                                    expression: "caseForm.limitDayCountType",
                                  },
                                },
                                _vm._l(_vm.validityList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "0",
                                prop: "limitDayCount",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "130px" },
                                attrs: {
                                  disabled: _vm.disabled,
                                  placeholder: "次数",
                                  size: "mini",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limitInputlength(
                                      _vm.caseForm.limitDayCount,
                                      _vm.caseForm,
                                      "limitDayCount",
                                      true
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.caseForm.limitDayCount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.caseForm,
                                      "limitDayCount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "caseForm.limitDayCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB10" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "适用级别" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.caseForm.vipLevelRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.caseForm,
                                    "vipLevelRangeType",
                                    $$v
                                  )
                                },
                                expression: "caseForm.vipLevelRangeType",
                              },
                            },
                            _vm._l(
                              _vm.dict.type.fill_vip_level_range_type,
                              function (dict) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dict.value,
                                    attrs: { label: parseInt(dict.value) },
                                  },
                                  [_vm._v(_vm._s(dict.label))]
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.caseForm.vipLevelRangeType == 2 ||
                                    _vm.caseForm.vipLevelRangeType == 1,
                                  expression:
                                    "\n                  caseForm.vipLevelRangeType == 2 ||\n                  caseForm.vipLevelRangeType == 1\n                ",
                                },
                              ],
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEvent(
                                        "openVipLevelDialog"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("指定会员等级")]
                              ),
                              _c("EditTable", {
                                attrs: { options: _vm.vipLevelOptions },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "PLUS会员权益" } }, [
                        _c(
                          "div",
                          { staticClass: "marT10" },
                          [
                            _c("el-upload", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "show-file-list": false,
                                "on-success": _vm.uploadSuccess,
                              },
                            }),
                            _c("quill-editor", {
                              ref: "QuillEditor",
                              staticClass: "marB20",
                              attrs: {
                                disabled: _vm.disabled,
                                options: _vm.editorOption,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onEditorChange($event)
                                },
                              },
                              model: {
                                value: _vm.caseForm.caseContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caseForm, "caseContent", $$v)
                                },
                                expression: "caseForm.caseContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "PLUS会员规则", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.disabled,
                              type: "textarea",
                              placeholder: "请输入PLUS会员规则",
                              maxlength: "600",
                              autosize: { minRows: 1, maxRows: 8 },
                            },
                            model: {
                              value: _vm.caseForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "remark", $$v)
                              },
                              expression: "caseForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "购买赠送" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "marB20" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseAfterDaysLargess1,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseAfterDaysLargess1",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseAfterDaysLargess1",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("购买"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth marR10",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.afterDays1,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "afterDays1", $$v)
                          },
                          expression: "caseForm.afterDays1",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("天后，"),
                      ]),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送优惠券"),
                      ]),
                      _c("SelectRemote", {
                        staticClass: "inputWidth marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCoupon",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: [false] },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponCaseId1,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCaseId1", $$v)
                          },
                          expression: "caseForm.couponCaseId1",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送张数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponCount1,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCount1", $$v)
                          },
                          expression: "caseForm.couponCount1",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays1,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays1", $$v)
                          },
                          expression: "caseForm.couponValidDays1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseAfterDaysLargess2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseAfterDaysLargess2",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseAfterDaysLargess2",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("购买"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth marR10",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.afterDays2,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "afterDays2", $$v)
                          },
                          expression: "caseForm.afterDays2",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("天后，"),
                      ]),
                      _c(
                        "span",
                        { staticClass: "fS14MR10 giftVoucherPackDay" },
                        [_vm._v("赠送券包")]
                      ),
                      _c("SelectRemote", {
                        staticClass: "inputWidth marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCouponPackage",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: 1 },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponPackageId2,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageId2", $$v)
                          },
                          expression: "caseForm.couponPackageId2",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送个数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponPackageCount2,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageCount2", $$v)
                          },
                          expression: "caseForm.couponPackageCount2",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays2,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays2", $$v)
                          },
                          expression: "caseForm.couponValidDays2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20 x-f" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseEveryMonthLargess3,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseEveryMonthLargess3",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseEveryMonthLargess3",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("每月"),
                      ]),
                      _c(
                        "div",
                        [
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              disabled: _vm.disabled,
                              option: {
                                data: _vm.monthList,
                                clearable: true,
                                value: "value",
                                label: "label",
                              },
                            },
                            model: {
                              value: _vm.caseForm.arrEveryMonthStr3,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "arrEveryMonthStr3", $$v)
                              },
                              expression: "caseForm.arrEveryMonthStr3",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fS14MR10" }, [_vm._v("号，")]),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("赠送优惠券"),
                      ]),
                      _c("SelectRemote", {
                        staticClass: "marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCoupon",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: [false] },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponCaseId3,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCaseId3", $$v)
                          },
                          expression: "caseForm.couponCaseId3",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送张数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponCount3,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCount3", $$v)
                          },
                          expression: "caseForm.couponCount3",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays3,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays3", $$v)
                          },
                          expression: "caseForm.couponValidDays3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20 x-f" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseEveryMonthLargess4,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseEveryMonthLargess4",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseEveryMonthLargess4",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("每月"),
                      ]),
                      _c(
                        "div",
                        [
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              disabled: _vm.disabled,
                              option: {
                                data: _vm.monthList,
                                clearable: true,
                                value: "value",
                                label: "label",
                              },
                            },
                            model: {
                              value: _vm.caseForm.arrEveryMonthStr4,
                              callback: function ($$v) {
                                _vm.$set(_vm.caseForm, "arrEveryMonthStr4", $$v)
                              },
                              expression: "caseForm.arrEveryMonthStr4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "fS14MR10" }, [_vm._v("号，")]),
                      _c("span", { staticClass: "fS14MR10 giftVoucherPack" }, [
                        _vm._v("赠送券包"),
                      ]),
                      _c("SelectRemote", {
                        staticClass: "inputWidth marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCouponPackage",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: [1] },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponPackageId4,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageId4", $$v)
                          },
                          expression: "caseForm.couponPackageId4",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送个数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponPackageCount4,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageCount4", $$v)
                          },
                          expression: "caseForm.couponPackageCount4",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays4,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays4", $$v)
                          },
                          expression: "caseForm.couponValidDays4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseEveryWeekLargess5,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseEveryWeekLargess5",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseEveryWeekLargess5",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("每周"),
                      ]),
                      _c("SelectLocal", {
                        staticClass: "marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          multiple: true,
                          disabled: _vm.disabled,
                          option: {
                            data: _vm.weekList,
                            clearable: true,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value: _vm.caseForm.arrEveryWeekStr5,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "arrEveryWeekStr5", $$v)
                          },
                          expression: "caseForm.arrEveryWeekStr5",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "fS14MR10 giftVoucherPackCoupon" },
                        [_vm._v("赠送优惠券")]
                      ),
                      _c("SelectRemote", {
                        staticClass: "inputWidth marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCoupon",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: [false] },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponCaseId5,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCaseId5", $$v)
                          },
                          expression: "caseForm.couponCaseId5",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送张数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponCount5,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponCount5", $$v)
                          },
                          expression: "caseForm.couponCount5",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays5,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays5", $$v)
                          },
                          expression: "caseForm.couponValidDays5",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marB20" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.caseForm.isUseEveryWeekLargess6,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.caseForm,
                              "isUseEveryWeekLargess6",
                              $$v
                            )
                          },
                          expression: "caseForm.isUseEveryWeekLargess6",
                        },
                      }),
                      _c("span", { staticClass: "fontS14 marL10" }, [
                        _vm._v("每周"),
                      ]),
                      _c("SelectLocal", {
                        staticClass: "marL10",
                        staticStyle: { width: "250px" },
                        attrs: {
                          multiple: true,
                          disabled: _vm.disabled,
                          option: {
                            data: _vm.weekList,
                            clearable: true,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value: _vm.caseForm.arrEveryWeekStr6,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "arrEveryWeekStr6", $$v)
                          },
                          expression: "caseForm.arrEveryWeekStr6",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "fS14MR10 giftVoucherPackWeek" },
                        [_vm._v("赠送券包")]
                      ),
                      _c("SelectRemote", {
                        staticClass: "inputWidth marR15",
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          option: _vm.$select({
                            key: "listCouponPackage",
                            option: {
                              option: {
                                clearable: true,
                                remoteBody: { isStops: [1] },
                              },
                            },
                          }).option,
                        },
                        model: {
                          value: _vm.caseForm.couponPackageId6,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageId6", $$v)
                          },
                          expression: "caseForm.couponPackageId6",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("赠送个数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponPackageCount6,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponPackageCount6", $$v)
                          },
                          expression: "caseForm.couponPackageCount6",
                        },
                      }),
                      _c("span", { staticClass: "fS14MR10 marL10" }, [
                        _vm._v("有效天数"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          size: "mini",
                        },
                        model: {
                          value: _vm.caseForm.couponValidDays6,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseForm, "couponValidDays6", $$v)
                          },
                          expression: "caseForm.couponValidDays6",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectCoupon", {
        attrs: { isRadio: true, OpenCoupon: _vm.openCoupon },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
      _c("selectStorePackage", {
        attrs: { isSelectPackageRadio: true, OpenPackage: _vm.openPackage },
        on: {
          "update:OpenPackage": function ($event) {
            _vm.openPackage = $event
          },
          "update:open-package": function ($event) {
            _vm.openPackage = $event
          },
          packageItemCom: _vm.packageItemCom,
        },
      }),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }