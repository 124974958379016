<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  saleRankListAPI,
  saleRankListSummaryAPI
} from '@/api/shop/report/saleRank' // 销售列表
export default {
  name: 'SaleRank',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '门店销售排名',
        getListApi: saleRankListAPI,
        getSummaryApi: saleRankListSummaryAPI,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/shop/report/sale/ranking/export',
          exportName: '门店销售排名'
        },
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'levelNos', label: '次卡编码' },
              { filter: 'levelNames', label: '次卡名称' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName-',
            label: '会员名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'levelNo',
            label: '级别编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'levelName',
            label: '级别名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'levelNo',
            label: '次卡编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'levelName',
            label: '次卡名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billQty',
            label: '总次数',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billQty',
            label: '使用次数',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billQty',
            label: '剩余次数',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '开始时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '到期时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'billQty',
            label: '购买金额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '购买时间',
            minWidth: 155,
            align: 'center'
          }
        ]
      }
    }
  }
}
</script>
