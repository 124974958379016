<template>
  <div class="wrap x-fc">
    <div class="y-c distance">
      <el-image
        class="cursorP"
        style="width: 160px; height: 130px"
        src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/f2d6b70b54334edfa81471cbd0fbc959.png"
        @click="publicAccount"
      />
      <span class="marB10">已有服务号</span>
      <el-button type="primary" size="mini" @click="publicAccount"
        >立即绑定</el-button
      >
    </div>
    <div class="y-c">
      <el-image
        class="cursorP"
        style="width: 160px; height: 130px"
        src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-05/fdf2b8d6b1044dfdb57f28107c98a3bd.png"
        @click="noPublicAccount('https://mp.weixin.qq.com/')"
      />
      <span class="marB10">没有服务号</span>
      <el-button
        type="primary"
        size="mini"
        @click="noPublicAccount('https://mp.weixin.qq.com/')"
        >立即申请</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  getTenantWxApp,
  getAuthorizationImageUrl,
  wxAppRemove,
} from "@/api/vip/wxService/authorize"; //微信公众号授权
export default {
  name: "authorize",
  methods: {
    //有公众号
    async publicAccount() {
      const res = await getTenantWxApp();
      if (res.data) {
        this.$confirm("已绑定微信公众号, 您是否要覆盖已有公众号授权?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await wxAppRemove([res.data.wxLineId]);
            const url = await getAuthorizationImageUrl(1);
            this.$message({
              type: "success",
              message: "覆盖成功!",
            });
            window.open(url, "_blank");
          })
          .catch();
      } else {
        const url = await getAuthorizationImageUrl(1);
        window.open(url, "_blank");
      }
    },
    //没公众号
    noPublicAccount(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  height: 100vh;
  width: 100%;
  .distance {
    margin-right: 150px;
  }
}
</style>
